import { Typography, Button, Card, CardContent, Box,Link } from "@mui/material";
import { useContext } from "react";
import {fetchModeratorDetails, selectPemifData} from '../../store/reducers/pemifReducer';
import { MainContext } from "../../context/Context";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCandidates } from "../../store/reducers/candidateReducer";
import { formatDateStr } from "../../utils/commonFunctions";
import { markSubmissionDateRange } from "../../utils/constants";
// import { selectPemifData } from "../../store/reducers/pemifReducer";
const SubmittedPopup = ({ qual }) => {
  const navigate = useNavigate();
  const { formValues } = useContext(MainContext);
  const {
    centre_number: cname,
    session_id,
    moderator_name,
    session_name,
    session_year,
    variant,
    margin,
    centre_number,
    centre_name,
    status,
  } = useSelector(selectPemifData);
  // const { candidate_details } = useSelector(selectCandidates);
  // const dispatch = useDispatch();
  // const auth = useAuth();

  // useEffect(() => {
  //   if (status === "idle" && cname && session_id)
  //     dispatch(
  //       fetchModeratorDetails({
  //         centre: cname,
  //         qual: location.state?.name,
  //         session: session_id,
  //         token: auth.user?.access_token,
  //       })
  //     );
  // }, [status, dispatch, cname, session_id, location.state?.name]);



  return (
    <Card
      sx={{
        display: "flex",
        padding: "10px",
        marginTop: "10px",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {qual.qualificationDisplayName}
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            We have already received your visit arrangements.
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            If you have any queries related to submitted form, please contact
            {/* <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link> */}
            <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            Submitted by: {formValues.submission_person_name.value}
          </Typography>
          <Typography variant="caption" sx={{ mt: "15px" }}>
            Submitted on: {formValues.declaration_date.value}
          </Typography>

          {formValues &&
            formValues.submit === "Y" &&
            formValues.expected_entries_indicator === "N" && (
              <Button
                onClick={() =>
                  navigate("/home/vaf", {
                    state: { name: qual.qualificationId.join(",") },
                  })
                }
                variant="contained"
                sx={{ fontSize: "x-small", width: "5rem", mt: "15px" }}
              >
                Edit
              </Button>
            )}
        </CardContent>
      </Box>
      {/* <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <Typography variant="caption"  component="div">
            If you have any queries related to submitted form, please contact
            <Link
              href="https://support.ocr.org.uk/"
              color="#0000e8"
              underline="always"
            >
              {"support@ocr.org.uk"}{" "}
            </Link>
          </Typography>
        </Box> */}
    </Card>
  );
};

export const VerifyUserPopup = ({ message='' }) => {
  return (
    <Card
      sx={{
        display: "flex",
        padding: "10px",
        marginTop: "10px",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          
          <Typography variant="caption" sx={{ mt: "15px" }}>
           {message}
          </Typography>
          
        </CardContent>
      </Box>
      
    </Card>
  );
};


export const MarkSubmissionPopup = ({ qual, candidateCount, wasSubmitted, submittedBy, submittedDate }) => {
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();

  const currDateForView = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();
  let endDate = markSubmissionDateRange.submissionEnd.setFullYear(currDateForView.getFullYear());

  return (
    <Card
      sx={{
        display: "flex",
        padding: "10px",
        marginTop: "10px",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            {qual.qualificationDisplayName}
          </Typography>
          { currDateForView >= endDate && candidateCount > 0 && (wasSubmitted != "N") ? (
            <>
              <Typography variant="caption" sx={{ mt: "15px" }}>
                Your centre’s marks have already been submitted. If you need to make changes now,
                please contact <a href="https://support.ocr.org.uk/">support@ocr.org.uk </a>
              </Typography>
              <Typography variant="caption" sx={{ mt: "15px" }}>
                If you would like to see your submitted marks, please click on the ‘VIEW’ button below
              </Typography>
              <Typography variant="caption" sx={{ mt: "15px" }}>
                Submitted by:{submittedBy}
              </Typography>
              <Typography variant="caption" sx={{ mt: "15px" }}>
                Submitted on: {formatDateStr(submittedDate)}
              </Typography>
              <Button
                onClick={() =>
                  navigate("/home/markSubmission/view", {
                    state: { name: JSON.stringify(qual.qualificationId[0]) },
                  })
                }
                variant="contained"
                sx={{ fontSize: "x-small", width: "5rem", mt: "15px" }}
              >
                View
              </Button>
            </>
          ) : (
            <>
              <Typography variant="caption" sx={{ mt: "15px" }}>
                Your centre has not submitted entries for this qualification.
              </Typography>
            </>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};
export default SubmittedPopup;
