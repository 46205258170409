import React, { useEffect } from "react";
import StepActionsMarksSubmission from "../StepActionsMarksSubmission";
import { CommonHeaderDetails } from "../CommonHeaderDetails";
import ALevelTable from "./ALevelTable";
import { useLocation } from "react-router-dom";
import EntryLevelTable from "./EntryLevelTable";
import GCSELevelTable from "./GCSELevelTable";
import { QualMapping } from "../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActivities,
  selectActivity,
} from "../../../store/reducers/activityReducer";
import { fetchRole, selectRole } from "../../../store/reducers/roleReducer";
import { useAuth } from "react-oidc-context";
import StaticData from "./StaticData";
import {
  fetchExceptionalActivity,
  selectExceptionActivity,
} from "../../../store/reducers/exceptionalActivityReducer";
import { selectPemifData } from "../../../store/reducers/pemifReducer";
import FormSubmissionError from "./FormSubmissionError";
import { Box } from "@mui/material";
export default function StepTwo() {
  const auth = useAuth();
  const accessToken = auth.user?.access_token;
  const location = useLocation();
  const dispatch = useDispatch();
  const { status } = useSelector(selectActivity);
  const { status: roleStatus } = useSelector(selectRole);
  const { status: noEventStatus } = useSelector(selectExceptionActivity);
  const {submitStatus} = useSelector(selectPemifData);
  useEffect(() => {
    if (status === "idle")
      dispatch(
        fetchActivities({ qual: location.state?.name, token: accessToken })
      );
  }, [status, dispatch, location.state?.name]);
  useEffect(() => {
    if (roleStatus === "idle")
      dispatch(fetchRole({ qual: location.state?.name, token: accessToken }));
  }, [roleStatus, dispatch, location.state?.name]);
  useEffect(() => {
    if (noEventStatus === "idle")
      dispatch(
        fetchExceptionalActivity({
          qual: location.state?.name,
          token: accessToken,
        })
      );
  }, [noEventStatus, dispatch, location.state?.name]);

  return (
    <Box class="marksubmission" sx={{mt:-4}} >
      <StaticData qual={location.state?.name} />

      {location.state?.name === QualMapping.ENTRY ? (
        <EntryLevelTable />
      ) : location.state?.name === QualMapping.GCSE ? (
        <GCSELevelTable />
      ) : location.state?.name === QualMapping.ALEVEL ? (
        <ALevelTable qual="aLevel" />
      ) : (
        <ALevelTable qual="asLevel" />
      )}
       {submitStatus === "failed" && <FormSubmissionError/>}
      <StepActionsMarksSubmission
        isError={() => false}
        // isError={() =>
        //   QualMapping.ALEVEL || QualMapping.ASLEVEL ? isInvalid : false
        // }
      />
    </Box>
  );
}
