import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  QualMapping,
  activityArray,
  entryActivityArray,
  gcseActivityArray,
  entryOptions,
  gcseOptions,
  exceptionMarkActivitiesALevelArray,
  averageFirstAndSecondMarkActivities,
  averageAllFirstSecondAndThirdMarkActivities,
  gcseOffsiteActivities
} from "../../utils/constants";
import { containsEvent } from "../../utils/commonFunctions";
import { CookieSharp } from "@mui/icons-material";
const isValidMark = (value, range) => {
  if (isNaN(value)) {
    return false;
  }
  if (value % 1 !== 0) {
    return false;
  }
  return value >= 0 && value <= range;
};

export const calculateOverallMarkForGCSEAndEntry = (rows) => {
  let overallMark = 0;
  let isOverallValid = true;
  rows.forEach((row) => {
    const mark = row["total_for_activity"];
    if (mark === "" || mark === "-") isOverallValid = false;
    overallMark += mark ? parseInt(mark) : 0;
  });
  return isOverallValid ? overallMark : "-";
};

export const calculateMarks = (row, action) => {
  const eventMarks = [
    "first_event_mark",
    "second_event_mark",
    "third_event_mark",
  ];
  let totalMarks = 0;
  let counter = 0;

  // Validate we have sufficient marks to be able to calculate
  if (
    (row.activity_id &&
      action.payload &&
      action.payload.qual &&
      action.payload.qual == "aLevel" &&
      exceptionMarkActivitiesALevelArray.includes(parseInt(row.activity_id)) &&
      (row.first_event_mark === "" || row.second_event_mark === "")) ||
    (row.activity_id &&
      averageFirstAndSecondMarkActivities[action.payload.qual].includes(
        parseInt(row.activity_id)
      ) &&
      (row.first_event_mark === "" || row.second_event_mark === "")) ||
    (row.activity_id &&
      averageAllFirstSecondAndThirdMarkActivities[action.payload.qual].includes(
        parseInt(row.activity_id)
      ) &&
      (row.first_event_mark === "" ||
        row.second_event_mark === "" ||
        row.third_event_mark === "")) ||
    row.first_event_mark === ""
  ) {
    // Handle scenario for Coach or Leader - Only requires first event mark
    if (action.payload.qual &&
      row.first_event_mark &&
      row.role &&
      (row.role == "Coach" || row.role == "Leader") &&
      (action.payload.qual === "aLevel" ||
      action.payload.qual === "asLevel" ||
      action.payload.qual === "gcse"))
      return row.first_event_mark
    else
      // Mark cannot be calculated as one of the values is missing
      return "-";
  }

  // We have the marks required so calculate the overall mark
  for (const eventMark of eventMarks) {
    const mark = row[eventMark];

    // A Level exception where calculation is slightly different for some subjects
    // CASS-900 - Inconsistent mark calculation, needed to parseInt as activity_id from previous data is a string
    if (
      row.activity_id &&
      action.payload &&
      action.payload.qual &&
      action.payload.qual == "aLevel" &&
      exceptionMarkActivitiesALevelArray.includes(parseInt(row.activity_id))
    ) {
      switch (eventMark) {
        case "first_event_mark":
          totalMarks += mark ? parseInt(mark) / 3 : 0;
          break;
        case "second_event_mark":
          totalMarks += mark ? (parseInt(mark) * 2) / 3 : 0;
          break;
        default:
          totalMarks += mark ? parseInt(mark) : 0;
          break;
      }
      //totalMarks += mark? parseInt(mark) : 0;
    } else {
      if (mark) {
        counter++;
        totalMarks += parseInt(mark);
      }
    }
  }

  const averageMark = Math.round(totalMarks / (counter !== 0 ? counter : 1));

  return averageMark;
};

const handleActivity = (state, action, name, value) => {
  const act = action.payload.data.filter(
    (d) => action.payload.event["value"] == d.activity_id
  )[0];
  state.candidates[action.payload.id]["a_as_lvl_activities"]["activity_name"] =
    act.activity_name;
  state.candidates[action.payload.id]["a_as_lvl_activities"][name] = value;

  // Update whether filmed evidence is required
  if (act.activity_type == "off-site")
  {
    state.candidates[action.payload.id]["a_as_lvl_activities"]["filmed_evidence_to_be_provided"] = "Y";
    state.candidates[action.payload.id]["a_as_lvl_activities"]["onsite_filmed_evidence_available_value"] = "N";
    state.candidates[action.payload.id]["a_as_lvl_activities"]["onsite_filmed_evidence_available"] = "N";
  }
  else
    state.candidates[action.payload.id]["a_as_lvl_activities"]["filmed_evidence_to_be_provided"] = "N";

};

// Find an event in the hierarchy starting with the specified activity ID
const findEvents = (data, activityId, eventId, isEventList) => {
  const tmpData = !isEventList
    ? data.filter((a) => a.activity_id === parseInt(activityId))
    : data;

  if (!tmpData) {
    return null;
  }

  const results = tmpData ? tmpData.filter((x) => x.event_id == eventId) : null;
  if (results && results.length > 0) {
    return results;
  } else {
    let res = null;
    tmpData.forEach((x) => {
      if (res == null) {
        if (x.next_level_events)
          res = findEvents(x.next_level_events, activityId, eventId, true);
        else res = findEvents(x.first_events, activityId, eventId, true);
      }
    });

    return res;
  }
};

const handleMarks = (state, action, markName) => {
  const value = action.payload.event.value;
  state.candidates[action.payload.id]["a_as_lvl_activities"][markName] =
    isValidMark(value, 30) ? value : "";
  state.candidates[action.payload.id]["overall_mark"] =
    state.candidates[action.payload.id]["a_as_lvl_activities"]["role"] ===
      "Coach" ||
    containsEvent(
      action.payload.noEvents,
      state.candidates[action.payload.id]["a_as_lvl_activities"]["activity_id"]
    )
      ? state.candidates[action.payload.id]["a_as_lvl_activities"][
          "first_event_mark"
        ]
      : calculateMarks(
          state.candidates[action.payload.id]["a_as_lvl_activities"],
          action
        );
};
const handleEvent = (state, action, eventName, name, value) => {
  const event = findEvents(
    action.payload.data,
    state.candidates[action.payload.id][action.payload.activityTypeCode][
      "activity_id"
    ],
    action.payload.event["value"]
  );
  state.candidates[action.payload.id]["a_as_lvl_activities"][eventName] =
    event.event_display_name;
  state.candidates[action.payload.id]["a_as_lvl_activities"][name] = value;
};
const handleReset = (state, action, except) => {
  activityArray.map((name) => {
    if (!except.includes(name)) {
      state.candidates[action.payload.id]["a_as_lvl_activities"][name] = "";
    }
  });
  state.candidates[action.payload.id]["overall_mark"] = "";
};
const handleEntryReset = (state, action, except, options = []) => {
  entryActivityArray.forEach((name) => {
    if (!except.includes(name)) {
      // Bug fix for CASS-987
      action.payload.hasOwnProperty("activityTypeCode")
        ? (state.candidates[action.payload.id][action.payload.activityTypeCode][
            name
          ] = "")
        : options.length
        ? options.forEach((option) => {
            state.candidates[action.payload.id][option][name] = "";
          })
        : entryOptions.forEach((option) => {
            state.candidates[action.payload.id][option][name] = "";
          });
    }
  });
  state.candidates[action.payload.id]["overall_mark"] = "";
};
const handleResetGcse = (state, action, except, options = []) => {
  gcseActivityArray.forEach((name) => {
    if (!except.includes(name)) {
      action.payload.hasOwnProperty("activityTypeCode") &&
      action.payload.activityTypeCode
        ? (state.candidates[action.payload.id][action.payload.activityTypeCode][
            name
          ] = "")
        : options.length
        ? options.forEach(
            (option) => (state.candidates[action.payload.id][option][name] = "")
          )
        : gcseOptions.forEach(
            (option) => (state.candidates[action.payload.id][option][name] = "")
          );
    }
  });
  state.candidates[action.payload.id]["overall_mark"] = "";
};
export const fetchCandiates = createAsyncThunk(
  "candidates/fetchData",
  async ({ centre, qual, session, token }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_MARK_BASEURL}` +
        `markssubmissions/getcandidatedetails?centre_number=${centre}&qualification_id=${qual}&session_id=${session}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);
const initialState = {
  selectedActivities: { A: [], AS: [], GCSE: [], ENTRY: [] },
  candidate_details: {},
  candidates: [],
  status: "idle",
  error: null,
};
const candidateSlice = createSlice({
  name: "candidates",
  initialState: initialState,

  reducers: {
    handleSelectedActivities: (state, action) => {
      const { qualification, savedActivitiesList } = action.payload;
      return {
        ...state,
        selectedActivities: {
          ...state.selectedActivities,
          [qualification]: savedActivitiesList,
        },
      };
    },

    handleChange: (state, action) => {
      const { name, value } = action.payload.event;
      if (
        !activityArray.includes(name) &&
        !["pe_centre_contact_name"].includes(name)
      ) {
        handleReset(state, action, []);
        state.candidates[action.payload.id][name] = value;
      } else {
        if (name === "activity_id") {
          handleActivity(state, action, name, value);
          /* Don't clear the filmed_evidence_to_be_provided value as this is based on the activity selected */
          handleReset(state, action, ["activity_id", "activity_name", "filmed_evidence_to_be_provided", "onsite_filmed_evidence_available", "onsite_filmed_evidence_available_value"]);
        } else if (name === "first_event_mark")
          handleMarks(state, action, name);
        else if (name === "second_event_mark") handleMarks(state, action, name);
        else if (name === "third_event_mark") handleMarks(state, action, name);
        else if (name === "first_event_id") {
          handleReset(state, action, [
            "activity_id",
            "activity_name",
            "role",
            "first_event_name",
            "first_event_id",
            "filmed_evidence_to_be_provided",
            "onsite_filmed_evidence_available",
            "onsite_filmed_evidence_available_value",
          ]);
          handleEvent(state, action, "first_event_name", name, value);

        } else if (name === "second_event_id") {
          handleReset(state, action, [
            "activity_id",
            "activity_name",
            "role",
            "first_event_name",
            "first_event_id",
            "second_event_name",
            "first_event_mark",
            "second_event_id",
            "filmed_evidence_to_be_provided",
            "onsite_filmed_evidence_available",
            "onsite_filmed_evidence_available_value",
          ]);
          handleEvent(state, action, "second_event_name", name, value);

        } else if (name === "third_event_id") {
          handleReset(state, action, [
            "activity_id",
            "activity_name",
            "role",
            "first_event_name",
            "first_event_id",
            "second_event_name",
            "first_event_mark",
            "second_event_id",
            "third_event_id",
            "third_event_name",
            "second_event_mark",
            "filmed_evidence_to_be_provided",
            "onsite_filmed_evidence_available",
            "onsite_filmed_evidence_available_value",
          ]);
          handleEvent(state, action, "third_event_name", name, value);
        } else if (name === "role") {
          state.candidates[action.payload.id]["a_as_lvl_activities"][name] =
            value;

          // If this is a coach then we always record video evidence
          if (value == "Coach")
            state.candidates[action.payload.id]["a_as_lvl_activities"][
              "filmed_evidence_to_be_provided"
            ] = "Y";

          handleReset(state, action, [
            "activity_id",
            "activity_name",
            "role",
            "filmed_evidence_to_be_provided",
            "onsite_filmed_evidence_available_value" // If we're changing the role, don't reset this field
          ]);
        } else if (name === "onsite_filmed_evidence_available_value") {
          state.candidates[action.payload.id]["a_as_lvl_activities"][name] =
            value;
        } else if (name === "pe_centre_contact_name")
          state.candidate_details[name] = value;
      }
      if (
        !["pe_centre_contact_name"].includes(name) &&
        state.candidates[action.payload.id]["a_as_lvl_activities"]["role"] ===
          "Coach"
      ) {
        state.candidates[action.payload.id]["a_as_lvl_activities"][
          "onsite_filmed_evidence_available"
        ] = "N";
        state.candidates[action.payload.id]["a_as_lvl_activities"][
          "onsite_filmed_evidence_available_value"
        ] = "N";
      }

      return state;
    },
    resetCandidateData: (state, action) => {
      handleReset(state, action, []);
    },
    resetCandidates: (state) => {
      return initialState;
    },
    handleCandidateStatus: (state, action) => {
      const { name, value } = action.payload.event;
      const candidateIndex = state.candidates.findIndex(
        (candidate) => candidate.candidate_number === value.candidate_number
      );
      if (candidateIndex !== -1) {
        if (
          action.payload.qual === QualMapping.GCSE ||
          action.payload.qual === QualMapping.ENTRY
        ) {
          if (name === "absent") {
            action.payload.options?.map((activity) => {
              state.candidates[candidateIndex][
                activity
              ].candidate_attendance_status = "A";
            });
            action.payload.id = candidateIndex;
            if (action.payload.qual === QualMapping.ENTRY)
              handleEntryReset(state, action, [], action.payload.options);
            if (action.payload.qual === QualMapping.GCSE)
              handleResetGcse(state, action, [], action.payload.options);
          } else {
            const option =
              action.payload.qual === QualMapping.GCSE
                ? gcseOptions
                : entryOptions;
            option?.map((activity) => {
              state.candidates[candidateIndex][
                activity
              ].candidate_attendance_status = name === "present" ? "P" : "W";
              //reset all activites for absent candidates
              if (action.payload.qual === QualMapping.ENTRY)
                entryActivityArray.forEach((i) => {
                  state.candidates[candidateIndex][activity][i] = "";
                });
              if (action.payload.qual === QualMapping.GCSE)
                gcseActivityArray.forEach((i) => {
                  state.candidates[candidateIndex][activity][i] = "";
                });
            });
            if (name === "withdraw") {
              action.payload.id = candidateIndex;
              if (action.payload.qual === QualMapping.ENTRY)
                handleEntryReset(state, action, []);
              if (action.payload.qual === QualMapping.GCSE)
                handleResetGcse(state, action, []);
            }
          }
        } else {
          state.candidates[
            candidateIndex
          ].a_as_lvl_activities.candidate_attendance_status =
            name === "absent" ? "A" : name === "present" ? "P" : "W";
          if (name !== "present") {
            action.payload.id = candidateIndex;
            handleReset(state, action, []);
          }
        }
      }
    },
    setEvidenceData: (state, action) => {
      if (action.payload.qual === "alevel") {
        if (
          state.candidates[action.payload.id]["a_as_lvl_activities"]["role"] ===
          "Coach"
        ) {
          state.candidates[action.payload.id]["a_as_lvl_activities"][
            "filmed_evidence_to_be_provided"
          ] = "N";
          state.candidates[action.payload.id]["a_as_lvl_activities"][
            "onsite_filmed_evidence_available"
          ] = "N";
        } else {
          state.candidates[action.payload.id]["a_as_lvl_activities"][
            "filmed_evidence_to_be_provided"
          ] = action.payload.data.filmed_evidence_to_be_provided;
          state.candidates[action.payload.id]["a_as_lvl_activities"][
            "onsite_filmed_evidence_available"
          ] = action.payload.data.onsite_filmed_evidence_available;
        }
      } else if (action.payload.qual === "entry") {
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          "onsite_filmed_evidence_available"
        ] = action.payload.data.onsite_filmed_evidence_available;
      }
    },
    setEvidenceDataGcse: (state, action) => {
      state.candidates[action.payload.id][action.payload.activityTypeCode][
        "filmed_evidence_to_be_provided"
      ] = action.payload.data.filmed_evidence_to_be_provided;
      // state.candidates[action.payload.id][action.payload.activity][
      //   "onsite_filmed_evidence_available"
      // ] = action.payload.data.onsite_filmed_evidence_available;
    },
    handleEntryChange: (state, action) => {
      const { name, value } = action.payload.event;
      if (name === "sex") {
        state.candidates[action.payload.id][name] = value;
        handleEntryReset(state, action, []);
      } else if (name === "activity_id") {
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          "activity_name"
        ] = action.payload.data.filter(
          (d) => action.payload.event["value"] === d.activity_id
        )[0].activity_name;
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = value;
        handleEntryReset(state, action, ["activity_id", "activity_name"]);
      } else if (name === "role") {
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = value;
        handleEntryReset(state, action, [
          "activity_id",
          "activity_name",
          "role",
        ]);
      } else if (
        ["first_event_mark", "second_event_mark", "third_event_mark"].includes(
          name
        )
      ) {
        const value = action.payload.event.value;
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = isValidMark(value, 20) ? value : "";
        if (
          state.candidates[action.payload.id][action.payload.activityTypeCode][
            "role"
          ] === "Leader" ||
          containsEvent(
            action.payload.noEvents,
            state.candidates[action.payload.id][
              action.payload.activityTypeCode
            ]["activity_id"]
          )
        ) {
          state.candidates[action.payload.id][action.payload.activityTypeCode][
            "total_for_activity"
          ] =
            state.candidates[action.payload.id][
              action.payload.activityTypeCode
            ]["first_event_mark"];
        } else {
          state.candidates[action.payload.id][action.payload.activityTypeCode][
            "total_for_activity"
          ] = calculateMarks(
            state.candidates[action.payload.id][
              action.payload.activityTypeCode
            ],
            action
          );

          // Calculate the overall mark for all activity types
          state.candidates[action.payload.id]["overall_mark"] =
            calculateOverallMarkForGCSEAndEntry(
              entryOptions.map((x) => state.candidates[action.payload.id][x])
            );
        }
      } else if (
        ["first_event_id", "second_event_id", "third_event_id"].includes(name)
      ) {
        const eventName =
          name === "first_event_id"
            ? "first_event_name"
            : name === "second_event_id"
            ? "second_event_name"
            : "third_event_name";
        const event = findEvents(
          action.payload.data,
          state.candidates[action.payload.id][action.payload.activityTypeCode][
            "activity_id"
          ],
          action.payload.event["value"]
        );
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          eventName
        ] = event.event_display_name;
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = value;
        if (name === "first_event_id")
          handleEntryReset(state, action, [
            "activity_id",
            "activity_name",
            "role",
            "first_event_id",
            "first_event_name",
            "onsite_filmed_evidence_available",
            "onsite_filmed_evidence_available_value",
          ]);
        else if (name === "second_event_id")
          handleEntryReset(state, action, [
            "activity_id",
            "activity_name",
            "role",
            "first_event_id",
            "first_event_name",
            "second_event_id",
            "second_event_name",
            "first_event_mark",
            "onsite_filmed_evidence_available",
            "onsite_filmed_evidence_available_value",
          ]);
      } else if (name === "onsite_filmed_evidence_available_value") {
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = value;
      } else if (name === "reset") {
        handleEntryReset(state, action, []);
      }
    },
    handleChangeGcse: (state, action) => {
      const { name, value } = action.payload.event;
      if (name === "sex") {
        state.candidates[action.payload.id][name] = value;
        handleResetGcse(state, action, []);
      } else if (name === "activity_id") {
        const act = action.payload.data.filter(
          (d) => action.payload.event["value"] === d.activity_id
        )[0];

        state.candidates[action.payload.id][action.payload.activityTypeCode][
          "activity_name"
        ] = act.activity_name;
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = value;

        // Update the filmed_evidence_to_be_provided field as that is based on the activity
        if ((act.activity_type == "off-site") || gcseOffsiteActivities.includes(act.activity_id))
          state.candidates[action.payload.id][action.payload.activityTypeCode][
            "filmed_evidence_to_be_provided"
          ] = "Y";
        else
          state.candidates[action.payload.id][action.payload.activityTypeCode][
            "filmed_evidence_to_be_provided"
          ] = "N";

        // Don't reset filmed evidence row as that is calculated based on activity
        handleResetGcse(state, action, [
          "activity_id",
          "activity_name",
          "filmed_evidence_to_be_provided",
        ]);
      } else if (
        ["first_event_mark", "second_event_mark", "third_event_mark"].includes(
          name
        )
      ) {
        const value = action.payload.event.value;
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = isValidMark(value, 20) ? value : "";

        state.candidates[action.payload.id][action.payload.activityTypeCode][
          "total_for_activity"
        ] = calculateMarks(
          state.candidates[action.payload.id][action.payload.activityTypeCode],
          action
        );

        // Calculate the overall mark for all activity types
        state.candidates[action.payload.id]["overall_mark"] =
          calculateOverallMarkForGCSEAndEntry(
            gcseOptions.map((x) => state.candidates[action.payload.id][x])
          );
      } else if (
        ["first_event_id", "second_event_id", "third_event_id"].includes(name)
      ) {
        const eventName =
          name === "first_event_id"
            ? "first_event_name"
            : name === "second_event_id"
            ? "second_event_name"
            : "third_event_name";
        const event = findEvents(
          action.payload.data,
          state.candidates[action.payload.id][action.payload.activityTypeCode]
            .activity_id,
          action.payload.event["value"]
        )[0];

        state.candidates[action.payload.id][action.payload.activityTypeCode][
          eventName
        ] = event.event_display_name;
        state.candidates[action.payload.id][action.payload.activityTypeCode][
          name
        ] = value;

        if (name === "first_event_id")
          handleResetGcse(state, action, [
            "activity_id",
            "activity_name",
            "first_event_id",
            "first_event_name",
            "filmed_evidence_to_be_provided",
          ]);
        else if (name === "second_event_id")
          handleResetGcse(state, action, [
            "activity_id",
            "activity_name",
            "first_event_id",
            "first_event_name",
            "second_event_id",
            "second_event_name",
            "first_event_mark",
            "filmed_evidence_to_be_provided",
          ]);
      }
      // else if (name === "filmed_evidence_to_be_provided") {
      //   state.candidates[action.payload.id][action.payload.activity][name] =
      //     value;
      // }
      else if (name === "reset") {
        handleResetGcse(state, action, []);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandiates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCandiates.fulfilled, (state, action) => {
        state.status = "succeeded";
        // CASS-1052 : Candidate validation is not performed in order on screen
        // Sort the candidates by candidate ID and add an index so we can identify order to submit in future
        state.candidates = action.payload.candidate_details.sort((a, b) => parseInt(a.candidate_number) < parseInt(b.candidate_number) ? -1 : 1);

        state.candidate_details = { ...action.payload };
        delete state.candidate_details.candidate_details;
      })
      .addCase(fetchCandiates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  handleChange,
  resetCandidates,
  resetCandidateData,
  setEvidenceData,
  setEvidenceDataGcse,
  handleCandidateStatus,
  handleEntryChange,
  handleChangeGcse,
  handleSelectedActivities,
} = candidateSlice.actions;
export const selectCandidates = (state) => state.candidates;
export default candidateSlice;
