import React, { useEffect } from "react";
import { Outlet, Link, useSearchParams } from "react-router-dom";
import { Typography, Grid, Grid2, Button, Divider, Link as MLink } from "@mui/material";

import { BannerImage } from "../common/BannerImage";
import { useDispatch } from "react-redux";
import { resetCandidates } from "../../store/reducers/candidateReducer";
import { resetActivity } from "../../store/reducers/activityReducer";
import { resetRole } from "../../store/reducers/roleReducer";
import { resetPemifData } from "../../store/reducers/pemifReducer";
import { LandingPageButtons } from "../common/LandingPageButtons";
import {visitArrangementsDateRange, markSubmissionDateRange, evidenceSubmissionDateRange} from "../../utils/constants";

function HomePage() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetCandidates());
    dispatch(resetActivity());
    dispatch(resetRole());
    dispatch(resetPemifData())
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();

  const checkVisitArrangementDisabled = () => {
    const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();
    const startDate = visitArrangementsDateRange.from.setFullYear(todaysDate.getFullYear());
    const endDate = visitArrangementsDateRange.to.setFullYear(todaysDate.getFullYear());

    return ( todaysDate < startDate || todaysDate > endDate)
  }
  const isVisitArrangementDisabled = checkVisitArrangementDisabled();

  const checkEvidenceSubmissionDisabled = () => {

    // Hack to allow our teams see the other areas out of the limited dates
    const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();

    const startDate = evidenceSubmissionDateRange.from.setFullYear(todaysDate.getFullYear());;
    const endDate = evidenceSubmissionDateRange.to.setFullYear(todaysDate.getFullYear());;

    return (todaysDate < startDate || todaysDate > endDate);

  }

  const isEvidenceSubmissionDisabled = checkEvidenceSubmissionDisabled();

  const checkMarkSubmissionDisabled = () => {
    const todaysDate = searchParams.get("testdate") && !isNaN(Date.parse(searchParams.get("testdate"))) ? new Date(searchParams.get("testdate")) : new Date();
    const startDate = markSubmissionDateRange.from.setFullYear(todaysDate.getFullYear());;
    const endDate = markSubmissionDateRange.to.setFullYear(todaysDate.getFullYear());;

    return ( todaysDate < startDate || todaysDate > endDate)
  }
  const isMarkSubmissionDisabled = checkMarkSubmissionDisabled()
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ width: "100%", Height: "100%", margin: "0px" }}
      >

        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            padding: 0,
            margin: 0,
          }}
        >
          <Grid item sm={2}/>
          <Grid
            item
            sx={{mt:2}}
            sm={8}
          >
            <Typography
               variant="body1"

            >
              Known previously as OCR PE Submissions,
              this service allows you to submit visit and moderation arrangement details and submit activity marks.
              You can save and return to the forms until you submit them to us.
              If you need help, please contact <a href="https://support.ocr.org.uk/">support@ocr.org.uk</a>.
            </Typography>

          </Grid>


          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              my: 4,
              mx: 1,
              mb: 0
            }}
          >
              <Grid
                item
                xs={12}
                sm={3}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography variant="h2" sx={{ mb: 2, fontSize: '1.125em !important' , fontWeight: 'bold'}}>
                  Visit arrangements
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  You will be able to submit your visit and moderation arrangements from {visitArrangementsDateRange.from.getDate()} {visitArrangementsDateRange.from.toLocaleString('default', { month: 'long' })}.
                </Typography>

              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography variant="h2" sx={{ mb: 2, fontSize: '1.125em !important' , fontWeight: 'bold'}}>
                  Marks submission
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  You will be able to submit your marks from {markSubmissionDateRange.from.getDate()} {markSubmissionDateRange.from.toLocaleString('default', { month: 'long' })}.
                </Typography>

              </Grid>

          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              my: 4,
              mx: 1,
              mt: 0
            }}
          >
              <Grid
                item
                xs={12}
                sm={3}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Button
                  variant="contained"
                  color="primaryHome"
                  sx={{ textTransform:"inherit", padding:"12px 16px", fontSize:"16px", width: "fit-content", mt: 2, backgroundColor: isVisitArrangementDisabled ?'darkgray !important': '', cursor:isVisitArrangementDisabled?'not-allowed': 'pointer'}}
                  component={Link}
                  disabled={isVisitArrangementDisabled}
                  to="/home"
                >
                  Go to visit arrangements
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                  <Button
                  variant="contained"
                  color="primaryHome"
                  sx={{ textTransform:"inherit",  padding:"12px 16px", fontSize:"16px", width: "fit-content", mt: 2, backgroundColor: isMarkSubmissionDisabled ?'darkgrey !important': '', cursor:isMarkSubmissionDisabled?'not-allowed': 'pointer'}}
                  component={Link}
                  disabled={isMarkSubmissionDisabled}
                  to="/home/markSubmission"
                >
                  Go to marks submission
                </Button>
                <Outlet />
              </Grid>

          </Grid>

          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              my: 4,
              mx: 1
            }}
          >
            <Grid
              item
              sm={8}
            >
              <Typography variant="h2" sx={{ mb: 2, fontSize: '1.125em !important' , fontWeight: 'bold'}}>
                Evidence submission
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Click the link to go to Submit for Assessment to upload your evidence.
              </Typography>
              <Button
                variant="text"
                color="primaryHome"
                sx={{ textTransform:"inherit", padding:"0px", fontWeight:"bold", fontSize:"16px", width: "fit-content"}}
                component={Link}
                to={process.env.REACT_APP_SFA_URL}
              >
                Submit for Assessment &gt;
              </Button>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
export default HomePage;
