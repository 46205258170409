export const isText = /^[A-Z ]+$/i;
export const isEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const isPhone = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/; // us
export const isZip = /^[0-9]{5}([- /]?[0-9]{4})?$/; // us
export const isNumber = /^\d+$/;
export const variant = "outlined";
export const margin = "normal";

// Availability dates for options
export const visitArrangementsDateRange = {
  from: new Date(1900, 0, 1), // 1st Jan
  to: new Date(1900, 5, 30) // 30th June
};

export const markSubmissionDateRange = {
  from: new Date(1900, 1, 1), // 1st Feb
  to: new Date(1900, 7, 22), // 22nd August
  submissionEnd: new Date(1900, 2, 15) // End date for submission of Marks - 15th March
};

export const evidenceSubmissionDateRange = {
  from: new Date(1900, 0, 1), // 1st Jan
  to: new Date(1900, 11, 31) // 31st Dec
};

export const aLevelColumns = [
  "Males",
  "Females",
  "Off-site",
  "Filmed",
  "Outdoor facilities",
  "Indoor facilities",
  "Extra Males?",
  "Extra Females?",
];
export const aLevelVenueColumns = ["Males", "Females", "Off-site", "Filmed"];

export const gcseLevelColumns = [
  "Males",
  "Females",
  "Off-site",
  "Outdoor facilities",
  "Indoor facilities",
  "Extra Males?",
  "Extra Females?",
];
export const gcseLevelVenueColumns = ["Males", "Females", "Off-site"];

export const entryLevelColumns = ["Males", "Females", "Live or filmed?"];
export const entryLevelVenueColumns = ["Males", "Females", "Being filmed?"];

export const commonLabels = {
  portalTitle: "PE Portal",
  myAccount: "My Account",
  signOut: "Sign Out",
  myCambrigeLinkText: "Go to My Cambridge",
  switchCenter: "Switch centre",
};

export const static_fields = [
  "session_id",
  "session_name",
  "qualifications",
  "expected_entries_indicator",
  "submit",
  "centre_host_venue_indicator",
  "a_level_activities",
  "as_level_activities",
  "gcse_activities_individual",
  "gcse_activities_team",
  "entrylvl_activities_individual",
  "entrylvl_activities_team",
];

export const QualMapping = {
  ENTRY: "97",
  ALEVEL: "440",
  ASLEVEL: "159",
  GCSE: "1787",
};


export const averageFirstAndSecondMarkActivities = {aLevel : [1 /* Acrobatic Gymnastics */,
                                                              9 /* Blind Cricket */,
                                                              10 /* BMX */,
                                                              15 /* Cricket */,
                                                              30 /* Gymnastics */,
                                                              66 /* Trampolining */],
                                                    asLevel : [1 /* Acrobatic Gymnastics */,
                                                                4 /* Athletics */,
                                                                9 /* Blind Cricket */,
                                                                15 /* Cricket */,
                                                                17 /* Cycling */,
                                                                74 /* Dance */,
                                                                30 /* Gymnastics */,
                                                                58 /* Swimming */,
                                                                66 /* Trampolining */],
                                                    gcse : [1 /* Acrobatic Gymnastics */,
                                                            4 /* Athletics */,
                                                            9 /* Blind Cricket */,
                                                            15 /* Cricket */,
                                                            17 /* Cycling */,
                                                            18 /* Dance (individual) */,
                                                            19 /* Dance (team) */,
                                                            30 /* Gymnastics */,
                                                            58 /* Swimming */,
                                                            66 /* Trampolining */],
                                                    entryLevel: [1 /* Acrobatic Gymnastics */,
                                                            4 /* Athletics */,
                                                            9 /* Blind Cricket */,
                                                            15 /* Cricket */]
                                                    };
export const averageAllFirstSecondAndThirdMarkActivities = {aLevel : [59 /* Table Cricket */],
                                                            asLevel : [59 /* Table Cricket */],
                                                            gcse : [59 /* Table Cricket */],
                                                            entryLevel : [59 /* Table Cricket */]};
// Activities that use a 1/3 + 2/3 formula for calculating marks (Athletics, Cycling, Swimming, Triathlon)
export const exceptionMarkActivitiesALevelArray = [4, /* Athletics */
                                                   17, /* Cycling */
                                                   58, /* Swimming */
                                                   67, /* Triathlon */];

// Offsite GCSE activities
export const gcseOffsiteActivities = [1, 2, 9, 11, 13, 16, 17, 20, 21, 23, 24, 28, 29, 35, 36, 37, 40, 41, 42, 43, 48, 49, 51, 52, 53, 54, 58, 59, 69, 70, 71, 72];

export const labels = [
  "First Step",
  "Second Step",
  "Third Step",
  "Fourth Step",
  "Fifth Step",
  "Confirmation",
];
export const entryLabels = [
  "First Step",
  "Second Step",
  "Third Step",
  "Fourth Step",
  "Fifth Step",
  "Sixth Step",
  "Confirmation",
];
export const marksSubmissionLabels = [
  "First Step",
  "Second Step",
  "Confirmation",
];
export const data = {
  // Removed hard coded dates????
  //session_id: "3379",
  //session_name: "June 2024",
  qualifications: [],
  centre_number: "",
  centre_name: "",
  expected_entries_indicator: "",
  submit: "",
  moderation_contact_name: null,
  moderation_contact_role: null,
  moderation_contact_email: null,
  moderation_contact_telephone: null,
  moderation_contact_extension: null,
  unavailable_dates: null,
  A_level_indicator: null,
  AS_level_indicator: null,
  moderation_type: null,
  centre_host_venue_indicator: "",
  centre_venue: null,
  facilities_addl_info: null,
  moderator_addl_info: null,
  submission_person_name: null,
  declaration_date: null,
  a_level_activities: [],
  as_level_activities: [],
  gcse_activities_individual: [],
  gcse_activities_team: [],
  entrylvl_activities_individual: [],
  entrylvl_activities_team: [],
};
export const gcseOptions = [
  "gcse_team_lvl_activities",
  "gcse_indiv_lvl_activities",
  "gcse_free_choice_activities",
];
export const entryOptions = [
  "entry_lvl_team_activities",
  "entry_lvl_indiv_activities",
  "entry_lvl_free_choice_one_activities",
  "entry_lvl_free_choice_two_activities",
  "entry_lvl_perf_analysis",
];
export const gcseActivity = [
  { label: "Team", value: "gcse_team_lvl_activities" },
  { label: "Individual", value: "gcse_indiv_lvl_activities" },
  {
    label: "Free Choice",
    value: "gcse_free_choice_activities",
  },
];
export const entryActivity = [
  { label: "Team", value: "entry_lvl_team_activities" },
  { label: "Individual", value: "entry_lvl_indiv_activities" },
  {
    label: "Free Choice 1",
    value: "entry_lvl_free_choice_one_activities",
  },
  {
    label: "Free Choice 2",
    value: "entry_lvl_free_choice_two_activities",
  },
  {
    label: "Performance",
    value: "entry_lvl_perf_analysis",
  },
];
export const activityArray = [
  "activity_id",
  "activity_name",
  "role",
  "first_event_id",
  "first_event_name",
  "first_event_mark",
  "second_event_id",
  "second_event_name",
  "second_event_mark",
  "third_event_id",
  "third_event_name",
  "third_event_mark",
  "filmed_evidence_to_be_provided",
  "onsite_filmed_evidence_available",
  "onsite_filmed_evidence_available_value",
];
export const gcseActivityArray = [
  "activity_id",
  "activity_name",
  "first_event_id",
  "first_event_name",
  "first_event_mark",
  "second_event_id",
  "second_event_name",
  "second_event_mark",
  "third_event_id",
  "third_event_name",
  "third_event_mark",
  "total_for_activity",
  "filmed_evidence_to_be_provided",
];
export const entryActivityArray = [
  "activity_id",
  "activity_name",
  "role",
  "first_event_id",
  "first_event_name",
  "first_event_mark",
  "second_event_id",
  "second_event_name",
  "second_event_mark",
  "third_event_id",
  "third_event_name",
  "third_event_mark",
  "total_for_activity",
  "onsite_filmed_evidence_available",
  "onsite_filmed_evidence_available_value",
];
