import React, { useCallback, useContext } from "react";
import StepActions from "../common/StepActions";
import { Typography, Box, Grid, Button } from "@mui/material";
import { MainContext } from "../../context/Context";
import { DateComponent } from "../common/DateComponent";
import { useSelector } from "react-redux";
import { selectPemifData } from "../../store/reducers/pemifReducer";

export default function FourthStep() {
  const {
    formValues,
    handleChange,
    handleBack,
    handleNext,
    handleSave,
    handleAddDate,
    handleRemoveDate
  } = useContext(MainContext);
  const { unavailable_dates } = formValues;

  const {
    session_year,
  } = useSelector(selectPemifData);

  const isError = useCallback(
    () =>
      unavailable_dates.some(dateObj => {
        // CASS-1087 - Catch scenario where end date is filled in but not start date or visa-versa
        if ((dateObj.easter_indicator === "N") &&
            ((dateObj.start_date.value === "" && dateObj.end_date.value !== "") ||
              (dateObj.end_date.value === "" && dateObj.start_date.value !== "")))
          return true;

        return Object.values(dateObj).some(date => {
          return (
            (date.required &&
              (date.value === "" || typeof date.value === "undefined")) ||
            date.error
          );
        });
      }),
    [formValues, unavailable_dates]
  );
  const handleDate = () => {
    if (unavailable_dates.length < 16) {
      handleAddDate();
    }
  };
  return (
    <>
      <Box sx={{ mt: 5, mb: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Dates your centre is unavailable for moderation
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Easter break
          </Typography>

          <Typography variant="subtitle2">Last day of spring term*</Typography>
          <DateComponent
            value={new Date(unavailable_dates[0].start_date.value)}
            name="unavailable_dates"
            handleChange={handleChange}
            dataset={{
              flag: "start_date",
              check: unavailable_dates[0].end_date.value,
              error: unavailable_dates[0].start_date.error,
              id: "0",
            }}
            error={!!unavailable_dates["0"].start_date.error}
            helperText={unavailable_dates[0].start_date.error}
            min={session_year+"-03-01"}
            max={session_year+"-05-31"}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">First day of summer term*</Typography>
          <DateComponent
            value={new Date(unavailable_dates[0].end_date.value)}
            name="unavailable_dates"
            handleChange={handleChange}
            dataset={{
              flag: "end_date",
              check: unavailable_dates[0].start_date.value,
              error: unavailable_dates[0].end_date.error,
              id: "0",
            }}
            error={!!unavailable_dates[0].end_date.error}
            helperText={unavailable_dates[0].end_date.error}
            min={session_year+"-03-01"}
            max={session_year+"-05-31"}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
            Other unavailable days
          </Typography>
          <Typography variant="subtitle2">
            Are there any other dates between 15 March and 15 May that your
            centre is unavailable?
          </Typography>
          <Typography variant="caption">
            If you are sharing a single day your centre is unavailable please
            enter this in the start and end date
          </Typography>
        </Grid>
        {unavailable_dates.map((d, i) => {
          if (d.easter_indicator === "N") {
            return (
              <React.Fragment key={i}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2">Start date</Typography>
                  <DateComponent
                    value={new Date(d.start_date.value)}
                    name="unavailable_dates"
                    handleChange={handleChange}
                    dataset={{
                      flag: "start_date",
                      check: d.end_date.value,
                      error: d.start_date.error,
                      id: `${i}`,
                    }}
                    error={!!d.start_date.error}
                    helperText={d.start_date.error}
                    min={session_year+"-03-15"}
                    max={session_year+"-05-15"}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle2">End date</Typography>
                  <DateComponent
                    value={new Date(d.end_date.value)}
                    name="unavailable_dates"
                    handleChange={handleChange}
                    dataset={{
                      flag: "end_date",
                      check: d.start_date.value,
                      error: d.end_date.error,
                      id: `${i}`,
                    }}
                    error={!!d.end_date.error}
                    helperText={d.end_date.error}
                    min={session_year+"-03-15"}
                    max={session_year+"-05-15"}

                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{mt:'20px'}}>
                  <Button variant="contained" color="error" onClick={()=>handleRemoveDate(i)}>Remove</Button>
                </Grid>
              </React.Fragment>
            );
          } else <></>;
        })}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "30%" }}
            onClick={handleDate}
          >
            Add more dates
          </Button>
        </Grid>
      </Grid>

      <StepActions
        isError={isError}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSave={handleSave}
      />
    </>
  );
}
