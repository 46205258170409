import React, { useEffect } from "react";
import { Divider, Typography, Grid, TextField, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { QualMapping } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import {
  fetchModeratorDetails,
  selectPemifData,
} from "../../../store/reducers/pemifReducer";
import { useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { selectCandidates } from "../../../store/reducers/candidateReducer";

export const StepOneHeaderDetails = ({ view }) => {
  const location = useLocation();
  const {
    centre_number: cname,
    session_id,
    moderator_name,
    session_name,
    session_year,
    variant,
    margin,
    centre_number,
    centre_name,
    status,
  } = useSelector(selectPemifData);
  const { candidate_details } = useSelector(selectCandidates);
  const dispatch = useDispatch();
  const auth = useAuth();

  useEffect(() => {
    if (status === "idle" && cname && session_id)
      dispatch(
        fetchModeratorDetails({
          centre: cname,
          qual: location.state?.name,
          session: session_id,
          token: auth.user?.access_token,
        })
      );
  }, [status, dispatch, cname, session_id, location.state?.name]);

  return (
    <>
      <Box sx={{ mb: 1, mt:-4}}>
      <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                mb: 1,
                fontSize: {
                  xs: "15px !important",
                  sm: "20px !important",
                  md: "20px !important",
                },
              }}
            >
              {location.state?.name === QualMapping.ENTRY
                ? `Entry Level Physical Education`
                : location.state?.name === QualMapping.GCSE
                ? `GCSE Physical Education`
                : location.state?.name === QualMapping.ALEVEL
                ? `A Level Physical Education`
                : `AS Level Physical Education`}{" "}
              activity marks submission {session_name} {session_year}
            </Typography>
        <Box sx={{ maxWidth: "58rem" }}>
          <Typography >

            <Typography variant="body2" sx={{ lineHeight: '1.8',  marginTop: '1%' }} >
              The students entered for this qualification are shown below.
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: '1.8',  marginTop: '1%' }} >
              Before you enter and submit your marks, you need to check and confirm the 'Sex assigned at birth' is correct for each student. This must be provided and may be different to the gender the student currently identifies as.
            </Typography>
            <Typography variant="body2" sx={{ lineHeight: '1.8',  marginTop: '1%' }} >
              Please make sure all students are showing as 'Male' or 'Female' and make any changes required and click 'Confirm' to continue.
            </Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
};
