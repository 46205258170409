import React, { useContext } from "react";
import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import StepActions from "../common/StepActions";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../context/Context";
import { QualMapping } from "../../utils/constants";
import { useSelector } from "react-redux";
import { selectPemifData } from "../../store/reducers/pemifReducer";


export default function FirstStep() {
  const location = useLocation();
  const { formValues, handleChange, handleNext, variant, margin } =
    useContext(MainContext);
  const {
    expected_entries_indicator,
    centre_name,
    centre_number,
    qualifications,
  } = formValues;
  const {
    session_name,
    session_year
  } = useSelector(selectPemifData);

  const getDate = () => {
    const date =
      qualifications &&
      qualifications.length &&
      new Date(qualifications[0].qualification_deadline);
    return date
      ? `${date?.getDate()} ${date?.toLocaleString("default", {
          month: "long",
        })}`
      : null;
  };
  return (
    <>
      <Box sx={{ mt: 5, mb: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Visit arrangement form
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          {location.state?.name === QualMapping.ENTRY
            ? "Entry Level Physical Education"
            : location.state?.name === QualMapping.GCSE
              ? "GCSE Physical Education"
              : "AS/A Level Physical Education"}
        </Typography>
        <Typography variant="caption">{session_name} {session_year} series</Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Complete this form with the contact details for your PE department so
          your moderator can get in contact with you. Please include an overview
          of your practical assessments to help your moderator plan a successful
          moderation visit. Please complete and submit this form as soon as
          possible but no later than {getDate()} .
        </Typography>
      </Box>
      <Divider variant="fullWidth" />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Centre number</Typography>
          <TextField
            variant={variant}
            margin={margin}
            name="centre_number"
            placeholder="Center number"
            value={centre_number}
            sx={{ width: "40%", mt: "3px" }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Centre name</Typography>
          <TextField
            variant={variant}
            margin={margin}
            name="centre_name"
            placeholder="Center name"
            value={centre_name}
            sx={{ width: "40%", mt: "3px" }}
            disabled
          />
        </Grid>
      </Grid>
      <Divider variant="fullWidth" sx={{ mt: 4 }} />
      <Typography variant="subtitle2" sx={{ fontWeight: "bold", mt: 2 }}>
        Please tell us if you plan to enter candidates for this qualification in {session_name} {session_year}{" "}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={expected_entries_indicator}
            name="expected_entries_indicator"
            onChange={handleChange}
          >
            <FormControlLabel value="Y" control={<Radio />} label="Yes" />
            <FormControlLabel value="N" control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
      </Grid>
      <StepActions
        isError={() => false}
        handleNext={handleNext}
        enterCandidates={expected_entries_indicator}
        isFirst={true}
      />
    </>
  );
}
