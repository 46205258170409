import React, { memo, useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button, MenuItem, Select, Divider } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import { MainContext } from "../../context/Context";
import { QualMapping } from "../../utils/constants";
import { ReactComponent as Logo } from "../../assets/ocr_logo_sml.svg";
import { verifyAndUnlockUser } from "../../store/reducers/pemifReducer";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";


function Header() {
  const { user, centre_name, centre_number, setCentreNumber } = useContext(UserContext);
  const { handleCenterDetails } = useContext(MainContext);
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const location = useLocation();  // Use the useLocation hook to get the current route

  const qual = location?.state?.name;

  // Testing of the re-login functionality,
  const [expiryTime, setExpiryTime] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      let secondsToGo = Math.round(auth.user?.expires_at - (Date.now()/1000));
      setExpiryTime(Math.floor(secondsToGo/60) + ":" + Math.round(secondsToGo % 60).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}));
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryTime])

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.assign(
      `${process.env.REACT_APP_MY_CAMBRIDGE_LOGOUT_LINK}?redirect_uri=${process.env.REACT_APP_IDENTITY_LOGOUT_REDIRECT_URL}&client_id=${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
    );
  };


  const getCenterId = center =>
    center?.sourceSystems?.length > 0
      ? center?.sourceSystems[0]?.orgId
      : center?.bpid;

  const renderCenterList = () => {
    return user?.orgs?.map(center => (
      <MenuItem key={center.name} value={getCenterId(center)}>
        {getCenterId(center)} - {center.name}
      </MenuItem>
    ));
  };

  useEffect(() => {
    handleCenterDetails(centre_name, centre_number);
  }, [centre_name, centre_number]);

  const handleUnlockUser = async () => {
    let qualName = qual === QualMapping.ENTRY
      ? 'Entry'
      : qual === QualMapping.GCSE
        ? 'GCSE'
        : qual === QualMapping.ALEVEL
          ? 'A'
          : 'AS'
    await dispatch(
      verifyAndUnlockUser({
        centre: centre_number,
        token: auth.user?.access_token,
        qual: qualName,
      })
    );

  };

  const handleDuelLogic = e => {
    setCentreNumber(e.target.value);
    handleUnlockUser()
    navigate("/");
  };

  return (
    <AppBar position="static">
      <Toolbar
        color="primary"
        variant="dense"
        sx={{ display: "flex", p: 1 }}>
        <Logo />
        <Divider
          orientation="vertical"
          variant="fullWidth"
          flexItem
          sx={{ mx: 4, width:"2px", backgroundColor:'lightgrey' }}
        />
        <Typography variant="h6" color="black" sx={{ flexGrow: 1, fontWeight:'bold' }}>
          OCR PE Moderation Service
        </Typography>

        { user && user.email && (user.email.endsWith("@dummy.com") || user.email === "bruce.storrie@cambridgeinternational.org") &&
        <Typography position="absolute" right="5px" top="0px">
        {expiryTime}
        </Typography>
        }

        <Select
          value={centre_number}
          onChange={e => handleDuelLogic(e)}
          name={centre_name}
          style={{ color:'black', width: "25%", border:"2px solid rgba(67, 90, 96, 0.5)" }}>
          {renderCenterList()}
        </Select>

        <Button variant="outlined" style={{ color:'black', borderWidth:'2px' }} onClick={handleLogout} sx={{ ml: 1, mr: 1 }}>
          Sign out
        </Button>
        <Button
          variant="outlined"
          style={{ color:'black', borderWidth:'2px' }}
          onClick={() => {
            window.location.replace(
              process.env.REACT_APP_MY_CAMBRIDGE_HOMEPAGE
            );
          }}>
          Go to My Cambridge
        </Button>

      </Toolbar>
    </AppBar>
  );
}

export default memo(Header);
